//
// This creates a masonry-like layout with CSS grid
// Pass an array of JSX elements and this will provide the offset grid layout
//
// From: https://medium.com/@andybarefoot/a-masonry-style-layout-using-css-grid-8c663d355ebb
//
import React, { useRef, useEffect } from "react"

import useViewportState from "../../hooks/useViewportState"
import styles from "./offsetGrid.module.scss"

const OffsetGrid = props => {
  const gridRef = useRef(null)

  function resizeGridItem(grid, item) {
    let rowHeight = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-auto-rows")
    )
    let rowGap = parseInt(
      window.getComputedStyle(grid).getPropertyValue("grid-row-gap")
    )
    let rowSpan = Math.ceil(
      (item.querySelector("[data-item-content]").getBoundingClientRect()
        .height +
        rowGap) /
        (rowHeight + rowGap)
    )
    item.style.gridRowEnd = "span " + rowSpan
  }

  function resizeAllGridItems(grid) {
    let allItems = Array.from(grid.querySelectorAll("[data-item]"))
    allItems.map((el, i) => resizeGridItem(grid, allItems[i]))
  }

  // Run once after rendering
  useEffect(() => {
    if (gridRef.current == null) return
    const grid = gridRef.current

    resizeAllGridItems(grid)
  })

  // Run grid measurements when viewport width changes
  useViewportState(
    width => {
      if (gridRef.current == null || width <= 768) return
      const grid = gridRef.current

      resizeAllGridItems(grid)
    },
    // Run the above only when the viewport width changes
    state => state.width
  )

  return (
    <>
      {props.items && (
        <ol ref={gridRef} className={styles.grid}>
          {props.items.map((item, i) => {
            return (
              <li className={styles.gridItem} key={`item-${i}`} data-item="">
                <div className={styles.gridItemContent} data-item-content="">
                  {item}
                </div>
              </li>
            )
          })}
        </ol>
      )}
    </>
  )
}

export default OffsetGrid
